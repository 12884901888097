<template>
  <v-container
    fluid
    class="pa-0"
  >
    <CreateOrganizationDialog
      v-model="modal"
      @created="organizationCreated"
      @error="handleError"
    />
    <v-tabs-items
      style="height: 100%;"
    >
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
        style="height: 100%;"
      />
      <v-row
        class="pt-0 pb-0"
        style="height: 100vh"
      >
        <v-col
          class="pt-0 pb-0 pr-0"
          cols="12"
          md="4"
          style="border-right: 1px solid #ddd;"
        >
          <v-card
            tile
            flat
            max-height="100vh"
            class="overflow-y-auto"
          >
            <OrganizationList
              :load-items-callback="getOrganizations"
              @selectOrganization="selectOrganization"
            />
          </v-card>
        </v-col>
        <v-col class="grey lighten-3">
          <OrganizationProfile
            v-if="selectedOrganization"
            :organization="selectedOrganization"
          />
        </v-col>
      </v-row>
    </v-tabs-items>
  </v-container>
</template>

<script>
import CreateOrganizationDialog from "@/components/CreateOrganizationDialog"
import OrganizationList from "@/components/organization/OrganizationList"
import OrganizationProfile from "@/components/organization/OrganizationProfile"

export default {
	name: 'Organizations',
	components: {
		CreateOrganizationDialog,
    OrganizationList,
    OrganizationProfile
	},
	data: () => ({
		modal: false,
    loading: false,
    selectedOrganization: null,
	}),
	methods: {
    createOrganization() {
      this.modal = true
    },
    selectOrganization(organization) {
      this.selectedOrganization = organization
    },
	  getOrganizations() {
      return this.$store.dispatch('getOrganizations')
    },
		organizationCreated() {
			this.modal = false
      this.$store.commit('updateActionSuccess', {
        message: this.$t('success'),
        subMessage: this.$t('organizationSuccessfullyCreated')
      })
		},
    exportOrganizations() {
      this.$store.commit('updateExportObjects', this.organizations)
    },
		handleError(message) {
			this.modal = false
			let subMessage = ''
			if(typeof message === 'string') {
				subMessage = message
			} else if(Array.isArray(message)) {
				message.forEach((m, index) => {
					try {
						m.message = JSON.parse(m.message)
						subMessage += `${m.message.alternateMessage}(${m.message.httpCode}): ${m.message.message}`
						if(index !== message.length -1) {
							subMessage += ' - '
						}
					} catch (e) {
						// do nothing
					}
				})
			}
      this.$store.commit('updateActionError', {
        message: this.$t('error'),
        subMessage: subMessage
      })
		}
	},
	computed: {
    tabs() {
      return [
        {
          title: this.$t('active'),
          key: 'active',
          badge: {
            color: 'green',
            content: 0
          }
        },
        {
          title: this.$t('inactive'),
          key: 'inactive',
          badge: {
            color: 'red',
            content: 0
          }
        }
      ]
    },
    query() {
      return this.$store.state.searchQuery
    },
		organizations: function() {
      if(this.query !== null && this.query.length > 0 ) {

        const query = this.query.reduce((accumulator, item) => accumulator + item.value, '')

        return this.$store.state.organizations.filter(function(organization) {

          if(organization.label !== null && typeof organization.label !== 'undefined') {
            if(organization.label.toLowerCase().includes(query.toLowerCase())) {
              return true
            }
          }

          if(organization.ssn !== null && typeof organization.ssn !== 'undefined') {
            const ssnQuery = query.replace('-', '')
            if(organization.ssn.includes(ssnQuery)) {
              return true
            }
          }

          return false
        }.bind(this))
      }

			return this.$store.state.organizations
		}
	},
	created() {
	  this.getOrganizations()
    this.$store.commit('updateAppBarExport', {
      callback: this.exportOrganizations
    })
    this.$store.commit('updateAppBarTabs', this.tabs)
    this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
    this.$store.commit('updateAppBarFab', {
      color: 'pink',
      callback: this.createOrganization
    })
	}
}
</script>
