<template>
  <div>
    <ActionList
      :list-items="organizations"
      :filters="filters"
      :actions="actions"
      :load-items-callback="loadItemsCallback"
    >
      <template v-slot:item="{ item, index }">
        <OrganizationListItem
          :organization="item"
          :key="index"
          @selectOrganization="selectOrganization(item)"
        />
        <v-divider inset />
      </template>
    </ActionList>
  </div>
</template>

<script>
import ActionList from "@/components/common/list/ActionList"
import OrganizationListItem from "@/components/organization/OrganizationListItem"

export default {
	name: 'OrganizationList',
	props: {
		organizations: {
			type: Array,
			default: () => []
		},
		loadItemsCallback: {
			type: Function,
			default: () => null
		}
	},
	components: {
		OrganizationListItem,
		ActionList
	},
	data() {
		return {
			tab: null,
			filters: [
				{
					key: 'address.name_line',
					label: this.$t('name'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'uuid',
					label: this.$t('UUID'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'phone',
					label: this.$t('phone'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'ssn',
					label: this.$t('SSN'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'email',
					label: this.$t('email'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'label',
					label: this.$t('label'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'discount',
					label: this.$t('discount'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'invoiceable',
					label: this.$t('invoiceable'),
					type: 'boolean',
					value: null,
					operator: 'equals'
				}
			]
		}
	},
	computed: {
		actions() {
			return this.$store.getters.bulkOperationsByRoute('organizations')
		}
	},
	methods: {
		selectOrganization(organization) {
			if (typeof organization.defaultCurrency === 'object') {
				organization.defaultCurrency = organization.defaultCurrency.code
			}
			this.$emit('selectOrganization', organization)
		}
	}
}
</script>
