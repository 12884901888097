<template>
  <v-list-item
    @click="$emit('selectOrganization', organization)"
    link
  >
    <v-list-item-avatar />  
    <v-list-item-content>
      <v-list-item-title>{{ organization.label }}</v-list-item-title>
      <v-list-item-subtitle>{{ organization.description }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
    name: 'OrganizationListItem',
    props: ['organization']
}
</script>